import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface Props {
    showForm: boolean;
    closeFormFindCustomGame: () => void;
}

const FormFindCustomGame: React.FC<Props> = ({ showForm, closeFormFindCustomGame }) => {

    const navigate = useNavigate();
    const user = JSON.parse(window.localStorage.getItem("atenaie-user")!).user;
    const [roomId, setRoomId] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        setIsModalOpen(showForm);
    }, [showForm]);

    const onExit = () => {
        closeFormFindCustomGame();
        setIsModalOpen(false);
    };

    const handleFindCustomGame = (customRoomId: string) => {
        navigate('/game', { state: { userName: user.userName, customGame: true, customRoomId: customRoomId } });
    };

    return (
        <>
            <Modal show={isModalOpen} onHide={onExit}>
                <Modal.Header closeButton onClick={onExit}>
                    <Modal.Title>Partida terminada</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="d-flex align-items-center justify-content-center">
                        <div className="border-left pl-md-4 pr-md-4">
                            <div className="form-group mb-3">
                                <label>Id de la sala</label>
                            </div>
                            <div className="form-group mb-3">
                                <input
                                    type="text"
                                    value={roomId}
                                    onChange={(e) => setRoomId(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleFindCustomGame(roomId)}>
                        Buscar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FormFindCustomGame;
