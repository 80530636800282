import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { Player } from '../interfaces/Player';

interface Props {
    players: Player[];
    isEndedGame: boolean;
    onNewGame: () => void;
}

const ModalEndGame: React.FC<Props> = ({ onNewGame, players, isEndedGame }) => {

    const navigate = useNavigate();
    const sortedPlayersByPoints = [...players].sort((a, b) => b.points! - a.points!);

    const onExit = () => {
        if (window.localStorage.getItem("atenaie-user")) {
            navigate('/home');
        } else {
            navigate('/');
        }
    };

    return (
        <>
            <Modal show={isEndedGame} onHide={() => { }}>
                <Modal.Header>
                    <Modal.Title>Partida terminada</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {players.length > 0 && (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="text-center" colSpan={2}>Jugadores</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedPlayersByPoints.map((player: Player, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td><b>{player.name}</b></td>
                                                <td><b>Puntos:</b> {player.points}</td>
                                            </tr>
                                            {index !== sortedPlayersByPoints.length - 1 && <tr><td colSpan={2}>&nbsp;</td></tr>}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>)}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="primary" onClick={onNewGame}>
                        Volver a jugar
                    </Button> */}
                    <Button variant="primary" onClick={onExit}>
                        Volver al inicio
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalEndGame;
