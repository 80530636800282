import imgCardAthena from '../resources/img/150x150/img_card_Athena.jpg';
import imgCardArchers from '../resources/img/150x150/img_card_Archers.jpg';
import imgCardCavalry from '../resources/img/150x150/img_card_Cavalry.jpg';
import imgCardDemigod from '../resources/img/150x150/img_card_Demigod.jpg';
import imgCardHeroes from '../resources/img/150x150/img_card_Heroes.jpg';
import imgCardInfantry from '../resources/img/150x150/img_card_Infantry.jpg';
import imgCardMessengers from '../resources/img/150x150/img_card_Messengers.jpg';
import imgCardNonCombatant from '../resources/img/150x150/img_card_NonCombatant.jpg';
import imgCardOracle from '../resources/img/150x150/img_card_Oracle.jpg';

export const getImageByCardType = (type: string): string => {
    switch (type) {
        case 'Athena':
            return imgCardAthena;
        case 'Archers':
            return imgCardArchers;
        case 'Cavalry':
            return imgCardCavalry;
        case 'Demigod':
            return imgCardDemigod;
        case 'Heroes':
            return imgCardHeroes;
        case 'Infantry':
            return imgCardInfantry;
        case 'Messengers':
            return imgCardMessengers;
        case 'NonCombatant':
            return imgCardNonCombatant;
        case 'Oracle':
            return imgCardOracle;
        default:
            return '';
    }
};

export const getColorButton = (color: string): string => {
    switch (color) {
        case 'White':
            return '#FFFFFF';
        case 'Gold':
            return '#c9b236';
        case 'Cyan':
            return '#6196f6';
        case 'Green':
            return '#5dac5d';
        case 'Blue':
            return '#679cf1';
        case 'Red':
            return '#b35442';
        case 'Yellow':
            return '#ccc538';
        case 'Silver':
            return '#9a9a9a';
        default:
            return '';
    }
};