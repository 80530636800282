import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import GamePage from './pages/GamePage';

function App() {
  return (
    <>
      {/* Rutas de la aplicación */}
      <Router>
        <Routes>
          <Route path="/" Component={LoginPage} />
          <Route path="/home" Component={HomePage} />
          <Route path="/game" Component={GamePage} />
        </Routes>
      </Router>
    </>
  );
}

export default App;