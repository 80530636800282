import { Card } from '../interfaces/Card';
import { getImageByCardType, getColorButton } from '../utils/PrintCard';

interface PlayerCardsProps {
    cards: Card[];
    playCard: (idCard: number) => void;
    isGameStarted: boolean;
}

const PlayerCards = ({ cards, playCard, isGameStarted }: PlayerCardsProps) => {
    return (
        <div>
            {(isGameStarted) && (<hr />)}
            
            {/* {(cards.length == 0 && isGameStarted) && (<h4 className="text-center mb-4">No tienes cartas para jugar</h4>)} */}

            {(cards.length > 0 && isGameStarted) && cards.map((card: Card, index) => (
                <button style={{ backgroundColor: getColorButton(card.color), border: 'none' }} key={index} className="btn btn-primary card-button" onClick={() => playCard(card.id)} disabled={!card.playable}>
                    <div className="d-flex flex-column align-items-center">
                        <img src={getImageByCardType(card.type)} className="mb-2" alt={card.description} />
                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{card.description} {!card.special ? card.number : ''}</span>
                    </div>
                </button>
            ))}
        </div>
    );
};

export default PlayerCards;