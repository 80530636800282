import { useEffect, useState } from 'react';
import { connectGameSocket, gameSocket, disconnectGameSocket } from '../server/WebSocketServer';
import { Player } from '../interfaces/Player';
import { Card, CardPlayed } from '../interfaces/Card';
import DashboardPlayers from '../components/DashboardPlayers';
import PlayerCards from '../components/PlayerCards';
import BetForm from '../components/BetForm';
import CardsPlayed from '../components/CardsPlayed';
import { playableCards } from '../utils/PlayableCards';
import { useLocation } from 'react-router-dom';
import { RingLoader } from 'react-spinners';
import ModalEndGame from '../components/ModalEndGame';
import PlayCardTimer from '../components/PlayCardTimer';
import BetTimer from '../components/BetTimer';
import ChatComponent from '../components/ChatComponent';

function GamePage() {

  connectGameSocket();

  const location = useLocation();
  const { userName, customGame, customRoomId } = location.state;

  const [title, setTitle] = useState('Buscando partida');
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [roomId, setRoomId] = useState('');
  const [players, setPlayers] = useState<Player[]>([]);
  const [playerCards, setPlayerCards] = useState<Card[]>([]);
  const [cardsPlayed, setCardsPlayed] = useState<CardPlayed[]>([]);
  const [myTurn, setMyTurn] = useState(false);
  const [newRound, setNewRound] = useState(false);
  const [isEndedGame, setIsEndedGame] = useState(false);

  const playCard = (idCard: number) => {
    if (gameSocket && gameSocket.connected) {
      gameSocket.emit('play-card', idCard);

      setPlayerCards((playerCards) => playerCards.filter((card) => card.id !== idCard));
      setMyTurn(false);
    } else {
      alert("Conexión perdida");
    }
  };

  const sendBet = (bet: number) => {
    if (gameSocket && gameSocket.connected) {
      gameSocket.emit('bet-round', bet);
      setNewRound(false);
    } else {
      alert("Conexión perdida");
    }
  };

  const onNewGame = () => {
    setIsEndedGame(false);
    setIsGameStarted(false);

    if (gameSocket && gameSocket.connected) {
      gameSocket.emit('find-game', { name: userName });
    } else {
      alert("Conexión perdida");
    }
  };

  const startCustomGame = () => {
    if (gameSocket && gameSocket.connected) {
      gameSocket.emit('start-custom-game', { roomId: roomId });
    } else {
      alert("Conexión perdida");
    }
  }

  useEffect(() => {

    if (gameSocket) {

      // Aquí se maneja si la partida es personalizada o no
      if (!customGame) {
        gameSocket.emit('find-game', { name: userName });
      } else {
        if (customRoomId) {
          gameSocket.emit('find-custom-game', { name: userName, roomId: customRoomId });
        } else {
          gameSocket.emit('create-custom-game', { name: userName });
          gameSocket.on('custom-game-created', (payload) => {
            setTitle('Partida creada, esperando jugadores...');
            setPlayers(payload.players);
            setRoomId(payload.roomId);
          });
        }
      }

      gameSocket.on('found-game', (payload) => {
        setTitle('Partida encontrada, esperando jugadores...');
        setPlayers(payload.players);
        setRoomId(payload.roomId);
      });

      gameSocket.on('player-added', (payload) => {
        setPlayers((players) => [...players, payload]);
      });

      gameSocket.on('start-game', () => {
        setTitle('');
        setIsGameStarted(true);
      });

      gameSocket.on('refresh-players', (payload) => {
        setPlayers(payload);
      });

      gameSocket.on('start-round', (payload) => {
        console.log("Se reciben evento start-round");

        setPlayerCards(payload);
        setNewRound(true);
      });

      gameSocket.on('start-turn', () => {
        setMyTurn(true);
      });

      gameSocket.on('card-played', (payload) => {
        setCardsPlayed((cardsPlayed) => [...cardsPlayed, payload.card]);
      });

      gameSocket.on('end-turn', (payload) => {
        setCardsPlayed(payload);

        setTimeout(() => {
          setCardsPlayed([]);
        }, 1800);
      });

      gameSocket.on('end-round', (payload) => {
        setPlayers(payload);
      });

      gameSocket.on('end-game', (payload) => {
        setTitle("La partida ha terminado");
        setPlayers(payload);
        setIsEndedGame(true);
      });
    }

    return () => {
      disconnectGameSocket();
    }

  }, [gameSocket]);

  useEffect(() => {
    let cardsWithPlayableFlag: Card[] = playableCards(playerCards, cardsPlayed, myTurn);
    setPlayerCards(cardsWithPlayableFlag);
  }, [myTurn]);

  // Alerta si se salen o refrescan la página con la partida empezada
  useEffect(() => {
    if (!isEndedGame) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [isEndedGame]);

  return (
    <div className="container-fluid">

      <div className="row">
        <DashboardPlayers userName={userName} players={players} roomId={roomId} />
      </div>

      <div className="row">
        <div className="col-md-8">

          <div className="row">
            {title && (<h2 className="text-center mt-4 mb-4">{title}</h2>)}
          </div>

          <div className="row">
            {!isGameStarted && (
              <div className="d-flex flex-column align-items-center mt-4">
                <RingLoader
                  color="#123456"
                  size={350}
                  speedMultiplier={0.8}
                />
              </div>
            )}

            {(customGame && !customRoomId && !isGameStarted) && (
              <div className="d-flex flex-column align-items-center mt-4">
                <button className="btn btn-primary align-items-center" onClick={() => startCustomGame()}>
                  ¡Empezar partida!
                </button>
              </div>
            )}
          </div>

          <div className="row">
            <div>
              <PlayCardTimer myTurn={myTurn} newRound={newRound} isEndedGame={isEndedGame} playCard={playCard} playerCards={playerCards} />
            </div>

            <div>
              <BetTimer newRound={newRound} isEndedGame={isEndedGame} sendBet={sendBet} />
            </div>

            <div>
              <BetForm newRound={newRound} sendBet={sendBet} numPlayerCards={playerCards.length} />
            </div>
          </div>

          <div className="row">
            <div className="text-center">
              <CardsPlayed myTurn={myTurn} cardsPlayed={cardsPlayed} newRound={newRound} isGameStarted={isGameStarted} />
            </div>
          </div>

          <div className="row">
            <div>
              <PlayerCards cards={playerCards} playCard={playCard} isGameStarted={isGameStarted} />
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <ChatComponent userName={userName} roomId={roomId} socket={gameSocket!} />
        </div>

        <ModalEndGame players={players} onNewGame={onNewGame} isEndedGame={isEndedGame} />

      </div>
    </div>
  );
}

export default GamePage;