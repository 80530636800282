import React, { useState } from 'react';

interface BetFormProps {
    newRound: boolean;
    sendBet: (bet: number) => void;
    numPlayerCards: number;
}

const BetForm = ({ newRound, sendBet, numPlayerCards }: BetFormProps) => {

    const [bet, setBet] = useState(0);

    const betChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = Number(e.target.value);

        // Creo que esto no es necesario - PedroLzr 27-10-24
        // if (value < 0) {
        //     value = 0;
        // } else if (value > numPlayerCards) {
        //     value = numPlayerCards;
        // }

        setBet(value);
    };

    // Creo que esto no es necesario - PedroLzr 27-10-24
    // useEffect(() => {
    //     if (newRound) {
    //         setBet(0);
    //     }
    // }, [newRound]);

    return (
        <div>
            {newRound && (
                <div className="input-group mb-3 d-flex flex-column align-items-center">
                    <div className="row">
                        <p>¿Cuantas bazas apuestas que ganarás esta ronda?</p>
                    </div>
                    <div className="row">
                        <input
                            type="number"
                            className="form-control"
                            // value={bet}
                            onChange={betChange}
                            min={0}
                            max={numPlayerCards}
                        />
                        <button className="btn btn-primary" onClick={() => sendBet(bet)}>
                            ¡Ofrecer!
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BetForm;
