import axios from 'axios';
import md5 from 'md5';
import { Endpoint } from '../enums/Endpoints';

const API_HOST: string = process.env.REACT_APP_API_HOST ?? 'http://localhost';
const API_PORT: string = process.env.REACT_APP_API_PORT ?? '3000';

const API_URL: string = API_HOST + ':' + API_PORT;

export const login = async (userName: string, password: string) => {

    try {
        password = md5(password);

        const response = await axios.post(API_URL + Endpoint.LOGIN, { userName, password });

        if (response.data) {
            window.localStorage.setItem("atenaie-user", JSON.stringify(response.data));
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}

export const newUser = async (userName: string, password: string) => {

    try {
        password = md5(password);
        
        const response = await axios.post(API_URL + Endpoint.USERS, { userName, password: password });

        if (response.data) {
            window.localStorage.setItem("atenaie-user", JSON.stringify(response.data));
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}
