import io, { Socket } from 'socket.io-client';

const API_HOST: string = process.env.REACT_APP_API_HOST ?? 'http://localhost';
const API_PORT: string = process.env.REACT_APP_API_PORT ?? '3000';
const API_URL: string = API_HOST + ':' + API_PORT;
const GAME_ENDPOINT: string = '/v1/game';

export let gameSocket: Socket | null;

export const connectGameSocket = () => {

    if (!gameSocket) {
        gameSocket = io(API_URL + GAME_ENDPOINT, {
            path: '/ws',
            withCredentials: false,
            // Se comenta para que no se reconecte automáticamente - PedroLzr 21-10-24
            // reconnectionAttempts: 5,
            // reconnectionDelay: 4000,
            transports: ['websocket', 'polling']
        });

        gameSocket.on('connect', () => {
            console.log('GameSocket conectado');
        });

        gameSocket.on('error', (error) => {
            console.log(`GameSocket error: ${error}`);
        });

        gameSocket.on('disconnect', () => {
            console.log('GameSocket desconectado');
        });
    }
}

export const disconnectGameSocket = () => {

    if (gameSocket) {
        gameSocket.disconnect();
        // gameSocket.removeAllListeners();
        gameSocket = null;
    }
}